import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "./src/context/store-context";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

export const onInitialClientRender = async () => {
  // Load React Axe
  if (process.env.NODE_ENV !== "production") {
    const { default: axe } = await import("react-axe");
    axe(React, ReactDOM, 1000);
  }
};
