export const Eina = {
  fontFamily: "Eina",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `
    url("/fonts/Eina01-Regular.woff") format("woff"),
    url("/fonts/Eina01-Regular.woff2") format("woff2")
  `
};

export const EinaSemiBold = {
  fontFamily: "Eina",
  fontStyle: "normal",
  fontWeight: 500,
  src: `
    url("/fonts/Eina01-SemiBold.woff") format("woff"),
    url("/fonts/Eina01-SemiBold.woff2") format("woff2")
  `
};

export const EinaBold = {
  fontFamily: "Eina",
  fontStyle: "normal",
  fontWeight: 700,
  src: `
    url("/fonts/Eina01-Bold.woff") format("woff"),
    url("/fonts/Eina01-Bold.woff2") format("woff2")
  `
};

export const EinaBolder = {
  fontFamily: "Eina",
  fontStyle: "normal",
  fontWeight: 900,
  src: `
    url("/fonts/Eina01-Bold.woff") format("woff"),
    url("/fonts/Eina01-Bold.woff2") format("woff2")
  `
};
