import { responsiveFontSizes, createMuiTheme } from "@material-ui/core";
import { Eina, EinaSemiBold, EinaBold, EinaBolder } from "./EinaFont";

const Theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#FFD71C"
      },
      secondary: {
        main: "#ED1C24"
      }
    },
    typography: {
      fontFamily: "Eina, sans-serif",
      h1: {
        fontSize: 75
      },
      h2: {
        fontSize: 60
      },
      h3: {
        fontSize: 40
      },
      h4: {
        fontSize: 30
      },
      h5: {
        fontSize: 28
      },
      h6: {
        fontSize: 22
      }
    },
    shape: {
      borderRadius: 0
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [Eina, EinaSemiBold, EinaBold, EinaBolder]
        }
      },
      MuiButton: {
        root: {
          fontWeight: "bold"
        },
        outlined: {
          borderWidth: "2px !important"
        }
      }
    }
  })
);

export default Theme;
