import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import Theme from "src/theme";

const App: React.FC = ({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>

      <ThemeProvider theme={Theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
